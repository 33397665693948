import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import Home from './components/Home.tsx';
import reportWebVitals from './reportWebVitals.js';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Success from './components/Success.tsx';
import Error from './components/Error.tsx';
import Index from './components/Index.tsx';
import Microform from './components/Microform.tsx';
import { CheckoutProvider } from './contexts/CheckoutContext.tsx';
import { CybersourceErrorProvider } from './contexts/CybersourceErrorContext.tsx';

export default function App() {
  return (
    <CheckoutProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Index />} />
          <Route path='/:uniqueCode' element={<Home />} />
          <Route path='/success' element={<Success />} />
          <Route path='/fail' element={<Error />} />
          <Route path='/checkout' element={<Microform />} />
        </Routes>
      </BrowserRouter>
    </CheckoutProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CybersourceErrorProvider>
      <App />
    </CybersourceErrorProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
