import { ReactNode } from "react";

function AppContainer({ children }: { children: ReactNode }) {
  return (
    <div className="bg-white p-8 rounded shadow-md w-full max-w-lg">

      <div className="flex items-center mb-2">
        <img src="/logo.png" alt="Logo" className="h-12" />
      </div>
      <div className="mb-4">
        <p className="text-xs p-0 my-0">ABN: 16 007 708 607</p>
        <p className="text-xs p-0 my-0">PH: (08) 8336 2066</p>
      </div>
      {children}
    </div>
  )
}

export default AppContainer;