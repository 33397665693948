import { getPaymentData, generateCaptureContext } from "../services/api.service";
import { useState, useEffect, useContext } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import AppContainer from './AppContainer';
import { useNavigate } from 'react-router-dom';
import { CheckoutContext } from "../contexts/CheckoutContext";

function Home() {

  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [formData, setFormData] = useState([]);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('info');
  let { uniqueCode }: any = useParams();
  const navigate = useNavigate();
  const context = useContext(CheckoutContext);
  const { setCheckoutData } = context;

  useEffect(() => {

    setIsLoading(true);
    getPaymentData({ uniqueCode }).then(res => {
      if (res.result) {
        setFormData(res.result);
        setPaymentMethod(res.result['payment_method']);
      } else if (res.message) {
        setMessage(res.message);
        setMessageType(res.type);
      }
      setIsLoading(false);
    });
  }, [uniqueCode]);

  const checkoutPayment = () => {
    setIsLoading(true);
    generateCaptureContext({ uniqueCode: uniqueCode }).then((res) => {
      setIsLoading(false);
      if (res.keyInfo && res.uniqueCode) {
        setCheckoutData({ keyInfo: res.keyInfo, uniqueCode: uniqueCode });
        navigate('/checkout');
      }
    }).catch((err) => {
      setIsLoading(false);
      console.error(err)
    });
  };

  return (
    <AppContainer>
      <div>
        {formData && formData['amount'] ?

          <>
            <h6>Billing Information</h6>
            <p>Please verify your details below, and click the "Next" button to continue. <br/>If any of the fields below are incorrect, please contact our customer service before proceeding with the payment.</p>
            <table className="table table-striped table-bordered">


              <tbody>
                <tr>
                  <td>First Name</td>
                  <td>{formData['bill_to_forename']}</td>
                </tr>
                <tr>
                  <td>Last Name</td>
                  <td>{formData['bill_to_surname']}</td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td>{formData['bill_to_address_line1']}</td>
                </tr>
                <tr>
                  <td>Suburb</td>
                  <td>{formData['bill_to_address_city']}</td>
                </tr>
                <tr>
                  <td>Postal Code</td>
                  <td>{formData['bill_to_address_postal_code']}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{formData['bill_to_email']}</td>
                </tr>
                <tr>
                  <td>Reference Number</td>
                  <td>{formData['reference_number']}</td>
                </tr>
                {/* <tr>
              <td>Transactiion Id</td>
              <td>{formData['transaction_uuid']}</td>
            </tr> */}
                <tr>
                  <td>Amount</td>
                  <td>${formData['amount']}</td>
                </tr>
              </tbody>
            </table>

            <>
              {paymentMethod === 'secure' ?
                <form id="payment_confirmation" action={formData['checkoutUrl']} method="post">
                  <input type="hidden" name="access_key" value={formData['access_key']} />
                  <input type="hidden" name="profile_id" value={formData['profile_id']} />

                  <input type="hidden" name="transaction_uuid" value={formData['transaction_uuid']} />
                  <input type="hidden" name="unsigned_field_names" />
                  <input type="hidden" name="signed_date_time" value={formData['signed_date_time']} />
                  <input type="hidden" name="locale" value={formData['locale']} />

                  <input type="hidden" name="bill_to_surname" value={formData['bill_to_surname']} />
                  <input type="hidden" name="bill_to_forename" value={formData['bill_to_forename']} />
                  <input type="hidden" name="bill_to_address_line1" value={formData['bill_to_address_line1']} />
                  <input type="hidden" name="bill_to_address_city" value={formData['bill_to_address_city']} />
                  <input type="hidden" name="bill_to_address_postal_code" value={formData['bill_to_address_postal_code']} />
                  <input type="hidden" name="bill_to_address_country" value={formData['bill_to_address_country']} />
                  <input type="hidden" name="bill_to_email" value={formData['bill_to_email']} />
                  <input type="hidden" name="signed_field_names" value={formData['signed_field_names']} />

                  <input type='hidden' name='transaction_type' value={formData['transaction_type']} />
                  <input type='hidden' name='reference_number' value={formData['reference_number']} />
                  <input type='hidden' name='amount' value={formData['amount']} />
                  <input type='hidden' name='currency' value={formData['currency']} />
                  <input type='hidden' name='unique_code' value={uniqueCode} />
                  <input type="hidden" name="submit" value="Submit" />

                  <input type="hidden" id="signature" name="signature" value={formData['signature']} />
                  <button type="submit" className="btn btn-primary">Next</button>
                </form>
                :
                <>
                  <br />
                  <button type="button" className="btn btn-primary" onClick={checkoutPayment} disabled={isLoading}>
                    {isLoading ? (
                      <>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        &nbsp;Loading...
                      </>
                    ) : (
                      'Next'
                    )}
                  </button>
                </>
              }
            </>
          </>

          :
          (messageType === 'info' ?
            <div id="info-box" className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded mb-6" role="alert">
              <span className="block sm:inline">{message}</span>
            </div>
            :
            <div id="warning-box" className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded mb-6" role="alert">
              <span className="block sm:inline">{message}</span>
            </div>
          )
        }


      </div>
      <ToastContainer />

    </AppContainer>
  );
}

export default Home;
