import { createContext, useState, useContext, ReactNode } from 'react';

interface CybersourceErrorContextState {
    errorMessage: string | null;
    setError: (error: string) => void;
    clearError: () => void;
}

const CybersourceErrorContext = createContext<CybersourceErrorContextState | undefined>(undefined);

interface CybersourceErrorProviderProps {
    children: ReactNode;
}

export const CybersourceErrorProvider: React.FC<CybersourceErrorProviderProps> = ({ children }) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const setError = (error: string) => {
        setErrorMessage(error);
    };

    const clearError = () => {
        setErrorMessage(null);
    };

    return (
        <CybersourceErrorContext.Provider value={{ errorMessage, setError, clearError }}>
            {children}
        </CybersourceErrorContext.Provider>
    );
};

export const useCybersourceError = (): CybersourceErrorContextState => {
    const context = useContext(CybersourceErrorContext);
    if (context === undefined) {
        throw new Error('useCybersourceError must be used within a CybersourceErrorProvider');
    }
    return context;
};
