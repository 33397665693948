import AppContainer from "./AppContainer";

function Success() {
  const message = 'Thank you. Your payment was successful. It is now safe to close this window.';
  return (
    <AppContainer>
      <div id="info-box" className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-6" role="alert">
        <span className="block sm:inline">{message}</span>
      </div>
    </AppContainer>
  )
}

export default Success;