import { useState, useRef, useLayoutEffect, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router';
import { createPayment } from "../services/api.service";
import AppContainer from './AppContainer';
import "../App.css";
import { CheckoutContext } from '../contexts/CheckoutContext';
import { useCybersourceError } from '../contexts/CybersourceErrorContext';

const Checkout: React.FC = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const flexResponseRef = useRef<HTMLInputElement>(null);
  const [errorsOutput, setErrorsOutput] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const context = useContext(CheckoutContext);
  const { checkoutData } = context;
  const { setError } = useCybersourceError();

  const YEARS_LENGTH = 22;

  useLayoutEffect(() => {

    const microFormstyles = {
      'input': {
        'font-size': '0.8rem',
        'font-family': 'helvetica, tahoma, calibri, sans-serif',
        'color': '#555',
        'line-height': '1.5rem',
      },
      ':focus': { 'color': 'blue' },
      ':disabled': { 'cursor': 'not-allowed' },
      'valid': { 'color': '#3c763d' },
      'invalid': { 'color': '#a94442' },
    };

    const flex = new (window as any).Flex(checkoutData.keyInfo);
    const microform = flex.microform({ styles: microFormstyles });
    const number = microform.createField('number', { placeholder: 'Enter card number' });
    const securityCode = microform.createField('securityCode', { placeholder: '•••' });

    number.load('#number-container');
    securityCode.load('#securityCode-container');

    const payButton = document.querySelector('#pay-button');
    payButton?.addEventListener('click', () => {
      const expMonth = document.querySelector('#expMonth') as HTMLSelectElement;
      const expYear = document.querySelector('#expYear') as HTMLSelectElement;

      const options = {
        expirationMonth: expMonth.value,
        expirationYear: expYear.value
      };

      setIsLoading(true);
      microform.createToken(options, (err: any, token: any) => {
        if (err) {
          setIsLoading(false);
          console.error(err);
          setErrorsOutput(err.message);
        } else {
          if (flexResponseRef.current) {
            flexResponseRef.current.value = JSON.stringify(token);
            confirmPayment(JSON.stringify(token));
          } else {
            setIsLoading(false);
          }
        }
      });
    });

  }, []);

  const confirmPayment = (token) => {
    createPayment({ uniqueCode: checkoutData.uniqueCode, flexresponse: token }).then((res) => {
      setIsLoading(false);
      let jsonResponse = JSON.parse(res?.paymentResponse);
      if (jsonResponse?.status === 'AUTHORIZED') {
        navigate('/success');
      } else {
        if (jsonResponse?.errorInformation) {
          setError(jsonResponse?.errorInformation?.message);
        }
        navigate('/fail');
      }
    }).catch((err) => {
      setIsLoading(false);
      console.error(err)
      navigate('/fail');
    });
  };

  return (
    <AppContainer>
      <h6>Card Details</h6>
      {errorsOutput && <><div id="errors-output" role="alert">{errorsOutput}</div><br /></>}
      <form id="checkout-form" ref={formRef} method="post">
        <div className="form-group">
          <label htmlFor="cardholderName">Name</label>
          <input id="cardholderName" className="form-control" name="cardholderName" placeholder="Name on the card" />
        </div>
        <div className="form-group">
          <label id="cardNumber-label">Card Number</label>
          <div id="number-container" className="form-control"></div>
        </div>
        <div className="form-group">
          <label htmlFor="securityCode-container">Security Code (CVV)</label>
          <div id="securityCode-container" className="form-control"></div>
        </div>
        <div className='row'>
          <div className="col-md-6">
            <div className='form-group'>
              <label htmlFor="expMonth">Expiry month</label>
              <select id="expMonth" className="form-select">
                <option>Month</option>
                {[...Array(12)].map((_, i) => (
                  <option key={i + 1} value={String(i + 1).padStart(2, '0')}>
                    {String(i + 1).padStart(2, '0')}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className='form-group'>
              <label htmlFor="expYear">Expiry year</label>
              <select id="expYear" className="form-select">
                <option>Year</option>
                {Array.from({ length: YEARS_LENGTH }, (_, i) => {
                  const currentYear = new Date().getFullYear();
                  return (
                    <option key={i} value={currentYear + i}>
                      {currentYear + i}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>

        <br />
        <input type="hidden" id="flexresponse" name="flexresponse" ref={flexResponseRef} />
        <input type="hidden" id="uniqueCode" name="uniqueCode" value={checkoutData.uniqueCode} />
        <button type="button" id="pay-button" className="btn btn-primary" disabled={isLoading}>
          {isLoading ? (
            <>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              &nbsp;Loading...
            </>
          ) : (
            'Pay'
          )}
        </button>
      </form>

    </AppContainer>
  );
};

export default Checkout;
