
import { environment as env } from "../environments/environment.ts";
import axios from 'axios';

const API_KEY = env.ApiKey;

/** This delegates the call to the API Proxy defined in index.js */
const sendGetRequest = async (url, params = {}) => {
  try {
    const response = await axios.get(env.baseurl + url, {
      method: "GET",
      url: env.baseurl + url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: API_KEY
      },
      params,
    });
    return response.data;
  } catch (err) {
    if (err?.response?.data) {
      return err.response.data;
    }
    return err;
  }
};

/** This delegates the call to the API Proxy defined in index.js */
const sendPostRequest = async (url, body = {}) => {
  try {
    const res = await axios.post(env.baseurl + url, body, {
      method: "POST",
      url: env.baseurl + url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: API_KEY
      },
    });

    return res.data;

  } catch (err) {
    if (err?.response?.data) {
      return err.response.data;
    }
    return err;
  };
};

export const getPaymentData = async (params = {}) => {
  try {
    let urlparams = {};
    urlparams.urlPrefix = 'get_payment_data/' + params.uniqueCode;
    const res = await sendGetRequest('api', urlparams);
    if (res.response?.status == 200 && res.response?.result) {
      const data = res.response.result.data;
      return data;
    } else {
      const data = res.response.msg.data;
      return data;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
}

export const generateCaptureContext = async (body = {}) => {
  try {
    const res = await sendPostRequest('generate_capture_context', body);
    if (res.data) {
      const data = res;
      return data;
    } else {
      const data = res;
      return data;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
}

export const createPayment = async (body = {}) => {
  try {
    const res = await sendPostRequest('confirm_payment', body);
    if (res.data) {
      const data = res;
      return data;
    } else {
      const data = res;
      return data;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
}
