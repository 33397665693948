import AppContainer from "./AppContainer";
import { useCybersourceError } from '../contexts/CybersourceErrorContext';

function Error() {
    const { errorMessage } = useCybersourceError();

    return (
        <AppContainer>
            <div id="error-box" 
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-6" role="alert">
                <span className="block sm:inline">
                    Sorry, The Payment processing failed. Please contact our customer support team.
                </span>
                <br/><br/>
                <span>
                    <b>More Details:</b> {errorMessage}
                </span>
            </div>
        </AppContainer>
    );
}

export default Error;