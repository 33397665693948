import { createContext, useState, ReactNode, FC } from 'react';

interface CheckoutData {
    keyInfo: string | null;
    uniqueCode: string | null;
}

interface CheckoutContextProps {
    checkoutData: CheckoutData;
    setCheckoutData: React.Dispatch<React.SetStateAction<CheckoutData>>;
}

const CheckoutContext = createContext<CheckoutContextProps | undefined>(undefined);

const CheckoutProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [checkoutData, setCheckoutData] = useState<CheckoutData>({
        keyInfo: null,
        uniqueCode: null,
    });

    return (
        <CheckoutContext.Provider value={{ checkoutData, setCheckoutData }}>
            {children}
        </CheckoutContext.Provider>
    );
};

export { CheckoutContext, CheckoutProvider };
